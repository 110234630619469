.change-log-container {
    display: flex;
    flex-flow: column;
    gap: 25px;
}
.change-log-container .grey-segment {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 30px;
}
.change-log-container .grey-segment input {
    min-width: 300px;
}
