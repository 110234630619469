.inbox-message-details-container {
    display: flex;
    flex-direction: column;
}
.inbox-message-details-container .segment {
    align-self: center;
    width: 50%;
}
.inbox-message-details-container .segment .section-row {
    justify-content: inherit;
}
.inbox-message-details-container .segment .section-row label {
    width: 20%;
}
.inbox-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
