.ui.dimmer > .certificate-view {
    display: flex;
    flex-direction: column;
    background: none !important;
    border-radius: 0 !important;
}
.ui.dimmer > .ui.modal.certificate-view-modal {
    max-width: 100vw !important;
    width: auto !important;
}
.ui.dimmer > .ui.modal.certificate-view-modal > .certificate-view {
    border-radius: 0 !important;
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    max-width: 100vw !important;
}
.certificate-front {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    background: #bdd9ff;
    min-height: 350px;
}
.front-left {
    padding-top: 15px;
    padding-left: 25px;
    width: 400px;
}
.front-right {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-right: 25px;
    width: 250px;
}
.front-headline {
    font-size: larger;
    font-weight: bold;
    max-width: 330px;
}
#positions {
    margin-bottom: 2px !important;
}
#coatOfArms {
    width: 70px;
    margin-left: 60px;
}
p.front-headline {
    margin-bottom: 5px !important;
}
.picture {
    display: flex;
    flex-direction: row;
    margin-left: 26px;
}
.picture-frame {
    width: 110px;
    height: 130px;
    border-style: solid;
    border-width: 1px;
}
.picture-frame img {
    width: 100%;
    height: 100%;
}
.ui.dimmer > .ui.small.active.modal.certificate-view-modal {
    background: transparent !important;
    box-shadow: none !important;
    min-width: 650px !important;
    margin: auto 0 !important;
}
.ui.dimmer > .certificate-view-modal .content .frame {
    padding: 20px !important;
}
#tag {
    color: white;
    text-align: center;
}
.certificate-back {
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    background: #bdd9ff;
    padding-top: 15px;
    padding-left: 25px;
}
.vonbiskm {
    display: flex;
    flex-direction: row;
}
.vonbis {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}
.default-certificate-view {
    border-radius: 15px;
    background: #f0f1f2;
    padding: 25px;
}
.default-certificate-view h3 {
    text-align: center;
}
.ui.dimmer > .certificate-view-modal > .actions {
    background: transparent !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.ui.dimmer > .certificate-view-modal > .actions button {
    min-width: 200px;
}

/* CH CERTIFICATES STYLES */

#ch-certificate-view {
    border-radius: 0 !important;
    color: black !important;
    font-size: 12px;
    width: 750px !important;
    font-family: Arial, Helvetica, sans-serif;
}
#ch-certificate-view > *,
#ch-certificate-view * {
    color: black !important;
}
#ch-certificate-view h4,
#ch-certificate-view h5 {
    text-align: center;
    margin-bottom: 15px;
}
#ch-certificate-view #upper-part,
#ch-certificate-view #middle-part,
#ch-certificate-view #lower-part {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
#ch-certificate-view #upper-part > section,
#ch-certificate-view #middle-part > section,
#ch-certificate-view #lower-part > section {
    flex: 1;
}
#ch-certificate-view #upper-part .left-section {
    text-align: center;
}
#ch-certificate-view #upper-part p#swiss-title {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: 15px auto;
}
#ch-certificate-view #upper-part p#swiss-title strong {
    width: 150px;
    flex: 2;
    font-size: 15px;
}
#ch-certificate-view #upper-part p#doc-number {
    display: flex;
    flex-direction: row;
    width: 40%;
    margin: 0 auto;
}
#ch-certificate-view #upper-part p#doc-number span {
    width: 100px;
    margin-left: 10px;
}
#ch-certificate-view #upper-part p {
    margin-bottom: 6px;
}
#ch-certificate-view #upper-part .right-section #type-box p {
    display: flex;
    flex-direction: row;
    line-height: 11px;
}
#ch-certificate-view #upper-part .right-section #type-box p span {
    flex: 2;
}
#ch-certificate-view #upper-part .right-section #type-box p strong {
    flex: 3;
    margin-left: -10px;
}
#ch-certificate-view #middle-part .right-section img {
    width: 100px;
    margin: 30px;
    margin-left: 280px;
}
#ch-certificate-view #lower-part p {
    margin-bottom: 6px;
    gap: 5px;
}
#ch-certificate-view #middle-part label {
    display: block;
    font-size: 6px;
}
.radar-view#ch-certificate-view #middle-part label {
    display: block;
    font-size: 8px;
}
#ch-certificate-view #middle-part .right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#ch-certificate-view #middle-part .right-section #sup-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
#ch-certificate-view #middle-part .right-section p {
    display: flex;
    flex-direction: row;
}

.padded-input {
    margin-left: 20px;
}
#ch-certificate-view #lower-part {
    margin-top: 10px;
}
#ch-certificate-view #lower-part p {
    display: flex;
    flex-direction: row;
}
#ch-certificate-view #lower-part .left-section p strong {
    min-width: 37px;
}
#ch-certificate-view #lower-part .right-section img {
    width: 120px;
}
#ch-certificate-view #footer {
    margin-top: 10px;
}
#ch-certificate-view #footer p#border {
    border-bottom: 1px solid black;
}
#ch-certificate-view #footer p {
    font-size: 6px;
    margin-bottom: 1px;
    display: flex;
    flex-direction: row;
}
#ch-certificate-view #footer span {
    margin-right: 20px;
}
#ch-certificate-view #upper-part #radarpatent-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* HIGH RHINE STYLES */

#high-rhine-view {
    border-radius: 0 !important;
    color: black !important;
    font-size: 13px;
    padding: 20px;
    width: 700px !important;
}
#high-rhine-view > *,
#high-rhine-view * {
    color: black !important;
}
#high-rhine-view #qual-id {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.underlined {
    border-bottom: 1px solid black;
}
#high-rhine-view #qual-id .underlined {
    width: 80px;
    text-align: center;
    margin-left: 10px;
}
#high-rhine-view .content-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
#high-rhine-view .content-row label {
    flex: 2;
}
#high-rhine-view .content-row span {
    flex: 3;
}
#high-rhine-view .content-row.small span {
    font-size: 10px;
}
#high-rhine-view .content-row .underlined.country {
    flex: 10;
}
#high-rhine-view #last-line {
    display: block;
    font-size: 10px;
    text-align: center;
    margin-top: 80px;
}

#high-rhine-view .signature-stamp {
    display: flex;
}

#high-rhine-view .signature {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 1rem;
}

#high-rhine-view .signature-stamp img {
    height: 24mm;
}

/* ADN & BOATMASTER ON THE RHINE STYLES */

#adn-card {
    border-radius: 0 !important;
    color: black !important;
    font-size: 13px;
    min-width: 900px;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
#adn-card > section {
    flex: 1;
}
#adn-card > section:first-of-type {
    border-right: 1px dashed gray;
}
#adn-card > *,
#adn-card * {
    color: black !important;
}
#adn-card p {
    margin-bottom: 3px;
}
#adn-card #front-container p {
    display: flex;
    flex-direction: row;
}
#adn-card label {
    margin-right: 15px;
}
#adn-card #signature-image {
    height: 50px;
}
#adn-card #front-container {
    display: flex;
    flex-direction: row;
}
#adn-card #info-container {
    flex: 2;
}
#adn-card #crew-member-image-container {
    flex: 1;
}
#adn-card #crew-member-image-container img {
    width: 100px;
}
#adn-card #back {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#adn-card #crew-member-image-container div {
    margin-top: 80px;
}
#radar-restrictions {
    display: inline-block;
}
#radar-restrictions span {
    display: block;
}

#adn-card .exam-stamp {
    display: flex;
}

#adn-card .exam {
    display: flex;
    flex-direction: column;
    width: 50%;
}

#adn-card #back img {
    height: 24mm;
}

/* EXTENSION BOATMASTER ON THE RHINE STYLES */

#extension-view {
    width: 600px !important;
    font-family: Arial, Helvetica, sans-serif;
}
#extension-view > *,
#extension-view * {
    color: black !important;
}
#extension-view p,
#extension-view h4 {
    margin-bottom: 4px;
}
#extension-view .centered {
    text-align: center;
}
#extension-view .crossed {
    text-decoration: line-through;
}
#extension-view label {
    font-size: 10px;
}
#extension-view p,
#extension-view strong {
    font-size: 12px;
}
#extension-view .content-row {
    display: flex;
    flex-direction: row;
}
#extension-view .content-row > * {
    flex: 1;
}
#extension-view section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#extension-view img {
    height: 50px;
    align-self: flex-start;
}
#extension-view .section-right {
    margin-left: auto;
}

#extension-view img {
    margin-right: 3rem;
    margin-left: auto;
    height: 24mm;
}

/* NEW STYLES FOR TEMPLATE CARDS */

.rheinpatents-view {
    border-radius: 0 !important;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
}
.rheinpatents-view * {
    color: black !important;
    font-weight: bold;
}
.rheinpatents-view > section {
    width: 500px;
    height: 316px;
    padding-left: 40px;
    padding-top: 62px;
}
.rheinpatents-view.hochrheinpatent > section {
    padding-top: 60px;
}
.rheinpatents-view.rheinpatent section#front {
    background: url("../../images/Rheinpatent_front_small.jpg");
}
.rheinpatents-view.rheinpatent section#back {
    background: url("../../images/Rheinpatent_back_small.jpg");
}
.rheinpatents-view.hochrheinpatent section#front {
    background: url("../../images/Hochrheinpatent_front_small.png");
}
.rheinpatents-view.hochrheinpatent section#back {
    background: url("../../images/Hochrheinpatent_back_small.png");
}
.rheinpatents-view > section {
    background-size: 500px 316px !important;
}
.rheinpatents-view p {
    margin-bottom: 0px !important;
}
.rheinpatents-view #signature-image {
    height: 50px;
    margin-bottom: 7px;
}
.rheinpatents-view #front-container {
    display: flex;
    flex-direction: row;
}
.rheinpatents-view #front-container #info-container {
    flex: 1;
}
.rheinpatents-view #crew-member-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.stamp-placeholder {
    width: 3cm;
    height: 3cm;
    border-radius: 50%;
    border: 1px dashed black;
    flex: inherit !important;
    margin-right: 20px !important;
}
.radar-view sup {
    font-size: 8px;
}
.radar-view#ch-certificate-view #footer p {
    font-size: 8px;
}
.right-section .details p span {
    flex: 3;
    margin-left: 46px;
}
.right-section .details p strong {
    flex: 3;
}
