.ucq-frame {
    border-style: solid;
    border-radius: 30px;
    background-color: #bdd9ff;
    padding-left: 20px;
    padding-right: 10px;
    width: 430px;
    margin: 0 auto;
}

.LNG-frame {
    width: 570px !important;
}

.headlineWithFlag {
    display: flex;
    flex-direction: row;
}

.ucq-frame .EU-img {
    height: 50px;
    width: 120px;
}

.ucq-frame .CCNR-img {
    height: 150px;
    width: 108px;
}

.ucq-frame .LNG-img {
    height: 70px !important;
    width: 120px !important;
}

.position {
    margin-bottom: 0px;
}

.photo {
    background-color: white;
    height: 120px;
    width: 100px;
    margin-top: 50px;
    display: flex;
    position: absolute;
    left: 74%;
}

.LNG-photo {
    left: 81% !important;
}

.photo img {
    width: 100%;
    height: 100%;
}

.middle {
    display: flex;
    flex-direction: row;
    position: relative;
}

.LNG-flex {
    display: flex;
    flex-direction: row;
}

.move-right {
    margin-left: 70px;
}

.LNG-sixth-position {
    position: absolute;
    right: 5px;
    top: 120px;
}
.none {
    display: none;
}
.certificate-qrcode-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 20px;
}
.certificate-qrcode-container img {
    width: 100px;
    height: 100px;
}
