#root .language-selector {
    padding: 10px !important;
    min-width: 60px !important;
    max-width: 60px !important;
    width: 100%;
}
#root .language-selector .divider.text {
    line-height: 18px !important;
}
#root .language-selector i.flag,
#root .language-selector .item i.flag {
    margin: 0 !important;
}
#root .language-selector i.flag {
    font-size: 22px !important;
}
#root .language-selector .menu.transition .item {
    padding: 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
