.statistics-container {
    display: flex;
    flex-flow: column;
    gap: 25px;
}
.statistics-container .statistics-segment {
    font-weight: bold !important;
}
.statistics-container .segment:first-of-type {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}
.statistics-container .segment:first-of-type .selection,
.statistics-container .segment:first-of-type button {
    width: 300px !important;
}
.statistics-container .segment .type-select {
    min-width: 650px !important;
}
.statistics-container .segment:first-of-type button {
    margin-bottom: 2px !important;
}
.statistics-container table button:first-of-type {
    margin-right: 10px !important;
}
.statistics-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
