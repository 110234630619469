.restrictions-container {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    opacity: 0;
    transition: ease-in-out all 0.5s;
    height: 0;
}

.restriction-visible {
    opacity: 1;
    min-height: 62px;
    height: 100%;
}

.restrictions-container label {
    font-weight: bold;
}
