.crew-member-container {
    display: flex;
    flex-direction: column;
}
.crew-member-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.crew-member-details-container > * {
    margin-bottom: 30px !important;
}
.crew-member-details-card {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 532px;
}
.crew-member-details-grid,
.card-header {
    display: flex;
    flex-flow: row wrap;
    gap: 50px;
}
.crew-member-details-card .card-half {
    flex: 1;
}
.card-header .card-half {
    align-self: center;
    margin-bottom: 30px;
}
.crew-member-details-card hr {
    width: 100%;
    border-top: 1px solid #cbd1d8;
    border-bottom: none;
    box-sizing: border-box;
}
.card-actions {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 20px;
    min-height: 36px;
}
.card-actions #dossier-link {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}
.add-certificate-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
.row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.add-certificate-form-container button,
.add-certificate-form-container .segment > .input-container {
    margin-top: 20px !important;
}
.add-authorization-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.crew-member-details-container .container-header h2 {
    margin-bottom: 0;
}
.crew-member-details-container .container-header {
    margin-bottom: 12px;
}
.add-certificate-modal .ui.divider {
    margin: 25px 0 !important;
}
#root .card-header .card-half img,
#root .card-header .card-half .ui.placeholder {
    width: 250px;
    height: 250px;
    align-self: center;
    object-fit: cover;
}
#root .card-header .card-half .ui.placeholder {
    background-image: linear-gradient(to right, #f0f1f2 0, #2f405440 15%, #f0f1f2 30%);
}
.add-certificate-modal .river-kilometers-form {
    margin-top: 10px;
}
div.card-half img {
    width: 200px !important;
    height: 259px !important;
}
.form-row {
    display: grid;
    gap: 50px;
    margin-bottom: 10px;
}
.form-row.two {
    grid-template-columns: repeat(2, 1fr);
}
.form-row.three {
    grid-template-columns: repeat(3, 1fr);
}
.form-row.four {
    grid-template-columns: repeat(4, 1fr);
}
.form-row.five {
    grid-template-columns: repeat(5, 1fr);
}
.form-row.six {
    grid-template-columns: repeat(6, 1fr);
}
#form-row-span-two-first {
    grid-column: 1/2;
}
#form-row-span-two-last {
    grid-column: 3/5;
}
#river-kilometers-maximum-message {
    padding: 3px 10px;
    margin-top: 0;
}
.crew-member-details-card i.chevron {
    position: absolute;
    margin: 0 !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    width: 35px !important;
    color: #2f4054;
    opacity: 1 !important;
}
.crew-member-details-card i.chevron.left {
    left: -25px;
}
.crew-member-details-card i.chevron.right {
    right: -60px;
}
.crew-member-details-card i.chevron.disabled {
    opacity: 0.4 !important;
}
.fade-enter.transition-container,
.fade-exit-active.transition-container {
    opacity: 0;
}
.fade-enter-active.transition-container,
.fade-exit.transition-container {
    opacity: 1;
}
.fade-enter-active.transition-container,
.fade-exit-active.transition-container {
    transition: opacity 300ms;
}
.modify-srb-modal,
.modify-certificate-modal {
    width: 750px !important;
}

@media only screen and (max-width: 730px) {
    .modify-certificate-modal {
        width: 95% !important;
    }
    .modify-certificate-modal .content {
        display: flex;
        flex-flow: column;
    }
}

.ui.inverted.dimmer > .crew-member-modal,
.ui.inverted.dimmer > .add-certificate-modal {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column;
    border-radius: 0 !important;
}
.crew-member-modal .header,
.add-certificate-modal .header {
    display: flex !important;
    flex-flow: row wrap;
    justify-content: center;
}
.crew-member-modal .header span,
.add-certificate-modal .header span {
    flex: 1;
    font-size: 24px;
}
.crew-member-modal .content {
    display: flex !important;
    flex-flow: row wrap;
    gap: 50px;
    overflow-y: auto !important;
    flex: 2;
}
.add-certificate-modal .content {
    overflow-y: auto !important;
    flex: 2;
}
.crew-member-modal .content > * {
    flex: 1 !important;
}
.crew-member-modal .content .image-dropzone-container img {
    margin: 0 auto;
}
.crew-member-modal .content .image-dropzone-container img {
    max-height: 40vh;
}
.crew-member-modal .content .image-dropzone-container.signature img {
    max-height: 20vh;
}
.crew-member-modal .actions {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}
.crew-member-modal .actions button {
    flex: 1;
}
#details-indicator {
    position: absolute;
    right: 25px;
}
#details-indicator .check {
    color: #43a047;
}
#eidas-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.card-images,
.card-half h2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.card-images #signature-image {
    width: 50mm !important;
    height: 19mm !important;
}
.srb-buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}
.crew-member-details-container table.table tbody tr td i.icon {
    margin-right: 0;
    margin-left: 2px;
    margin-bottom: 1px;
}
.crew-member-modal .content .image-dropzone-label {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}
.crew-member-modal .content .image-dropzone-label > label,
.crew-member-modal .content .image-dropzone-label > div {
    flex: 3;
}
.crew-member-modal .content .image-dropzone-label > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.crew-member-modal .content .image-dropzone-label .ui.toggle.checkbox label {
    color: #5f6774 !important;
    font-weight: bold;
}
img#signature-image.placeholder-signature {
    object-fit: contain !important;
}
.srb-form-modal .srb-function-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.srb-form-modal .srb-function-row:not(:last-of-type) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    padding-bottom: 5px;
}
.srb-form-modal .srb-function-row:not(:first-of-type) {
    padding-top: 5px;
}
.srb-form-modal .srb-function-row > section {
    flex: 3;
}
.srb-form-modal .srb-function-row i {
    font-size: 26px;
}
#remarks-field {
    white-space: pre-line;
}
.comments-reason-tooltip p {
    margin-bottom: 2px;
}
.comments-reason-tooltip span {
    white-space: pre;
}
@media only screen and (max-width: 730px) {
    .crew-member-details-card .card-header {
        flex-direction: column;
        text-align: center;
        gap: 0;
    }
    .crew-member-details-card .crew-member-details-grid {
        flex-direction: column;
        gap: 0;
    }
    .crew-member-details-card .card-actions {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }
    .crew-member-details-card .card-actions #dossier-link {
        position: unset;
        transform: unset;
    }
    .crew-member-details-card .card-actions #dossier-link button {
        width: 100%;
    }
    #root .crew-member-details-container .ui.segment table {
        min-width: 100% !important;
        max-width: 100% !important;
        table-layout: fixed;
    }
}
