.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #fff;
}
.login-form-container {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    background-color: #f0f1f2 !important;
    padding: 20px 40px;
    width: 400px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.center-login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 500px;
    width: 80vw;
    max-width: 700px;
    margin-bottom: 10px;
}
.login-form-container label {
    margin-left: auto;
}
.login-form-container hr {
    width: 100%;
    border-top: 1px solid #cbd1d8;
    border-bottom: none;
    box-sizing: border-box;
}
#root #login-button {
    background-color: #2f4054 !important;
}
#login-locked-message {
    margin-top: 20px;
    text-align: center;
    color: black !important;
}
.login-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
.left-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.upper-container,
.lower-container {
    flex: 1;
    background: #272f37;
}
.upper-container {
    border-top-left-radius: 10px;
    background: #417ab3;
}
.lower-container {
    border-bottom-left-radius: 10px;
    background: #2f4054;
}
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.login-wrapper > img {
    width: 200px;
    margin-bottom: 20px;
}
.language-select-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}
#cursor {
    cursor: pointer;
}
#centered-text {
    text-align: center !important;
}
#mini-logo {
    display: none;
}
#logo-text {
    display: none;
}
.login-form-container .password-reset-errors {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*responsive part */

@media only screen and (max-width: 635px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #fff;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .center-login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 500px;
        width: 100%;
        max-width: 340px;
        margin-bottom: 10px;
    }

    .left-container {
        flex: 3;
        display: flex;
        flex-direction: row;
        height: 100%;
        max-height: 420px !important;
        position: relative;
    }

    .login-form-container {
        flex: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        background-color: #f0f1f2 !important;
        padding: 30px 20px;
        width: 340px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0 !important;
    }

    .lower-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0 !important;
        background: #2f4054;
    }

    #mini-logo {
        display: block;
        width: 35%;
        fill: white;
    }

    #logo-text {
        display: block;
        width: 70%;
    }

    #logo {
        display: none;
    }

    #bmk-logo {
        display: none;
    }

    .upper-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
