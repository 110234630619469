.restriction96-container {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    opacity: 0;
    transition: ease-in-out all 0.5s;
    height: 0;
}

.restriction96-visible {
    opacity: 1;
    height: 88px;
}

.restriction96-container label {
    font-weight: bold;
}

.deliveryInfo-visible {
    opacity: 1;
    height: 280px;
}
