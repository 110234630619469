header {
    width: 100%;
    background-color: #2f4054;
}
header .menu {
    width: 90%;
    align-items: center;
}
header > div.menu .item {
    padding: 25px 20px !important;
    font-size: 15px;
    font-weight: bold !important;
    transition: all 0.15s ease-in-out !important;
    cursor: pointer;
    margin: 0 !important;
}
#root header > div.menu .item {
    color: #fff !important;
}
header .menu .item:hover,
header .menu .active.item {
    color: #bcbec0 !important;
    background-color: transparent !important;
}
header .dropdown .menu.transition {
    border-radius: 0 !important;
    margin-top: -2px;
    margin-left: -10px;
}
header .dropdown .menu.transition .item {
    min-height: 35px !important;
}
header > div.menu .item > i {
    margin: 0 10px !important;
}
header > div.menu .item .input > i {
    border-left: 2px solid #bcbec0;
    opacity: 1 !important;
    color: #5f6774 !important;
}
header > div.menu .right.item {
    padding: 0 !important;
    margin-right: 20px !important;
}

#logo-without-text {
    display: none;
}

.hamburger-icon {
    display: none !important;
}
.header-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
header > div.menu .item .label {
    left: 100% !important;
    top: -5px !important;
    color: #fff !important;
    background-color: #c0392b !important;
}
#root .cid-header-error input {
    background-color: #efc0bb !important;
}
@media only screen and (max-width: 1545px) {
    #logo-with-text {
        display: none;
    }
    #logo-without-text {
        display: flex;
        width: 88px;
        height: 57px;
    }
}

@media only screen and (max-width: 1365px) {
    .hamburger-icon {
        display: inherit !important;
        width: 35px;
        color: white !important;
    }

    .header-items {
        display: none !important;
    }

    #hamburger-modal {
        border-radius: 0 !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0;
        background-color: #2f4054;
    }

    .vertical-menu-hamburger-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 80%;
        background-color: #2f4054 !important;
        border-radius: 0 !important;
        padding: 0 10px !important;
    }

    .vertical-menu-hamburger-header > * {
        padding: 15px 0;
        width: 100%;
        border-bottom: 1px solid #f0f1f2 !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .vertical-menu-hamburger-header a i {
        color: #fff !important;
    }

    .hamburger-modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        border-bottom: 2px solid #f0f1f2;
    }

    .hamburger-modal-header .language-selector {
        min-width: 70px !important;
        max-width: 70px !important;
    }
    .hamburger-modal-header > a {
        width: auto !important;
    }

    .hamburger-modal-header > a img {
        width: 37.5px;
        height: 20px;
        align-self: center;
    }

    .header-menu-options {
        color: white !important;
        font-size: 20px !important;
        margin: 0;
    }

    .header-row-full-width-container {
        width: 100%;
        max-width: 350px !important;
        font-size: 16px !important;
    }
    div.ui.top.aligned.page.modals.dimmer.transition.visible.active {
        padding: 0;
    }
}
