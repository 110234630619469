.search-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 30px;
    align-items: flex-start;
}
.search-container .search-segment {
    font-weight: bold !important;
}
.search-container .segment:first-of-type {
    min-width: 550px;
    max-width: 550px;
}
.search-container .segment:last-of-type {
    flex: 2;
    align-self: flex-start;
}
.search-container .segment p {
    font-size: 16px;
}
.search-container .section-row button:first-of-type {
    width: 150px !important;
    padding: 11px !important;
}
.search-container .section-row .input,
.search-container .section-row .selection,
.search-container .section-row .date-select,
.search-container .section-row button:last-of-type {
    width: 330px !important;
}
.search-container hr {
    width: 100%;
    border-top: 1px solid #cbd1d8;
    border-bottom: none;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.date-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.date-select .selection.date-short {
    min-width: 90px !important;
    width: 90px !important;
}
.date-select .selection.date-long {
    min-width: 130px !important;
    width: 130px !important;
}

.error-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0;
    transition: ease-in-out all 0.5s;
    height: 0;
}

.visibility {
    opacity: 1;
    height: 17px;
}

.error {
    font-size: 12px !important;
    color: #c0392b !important;
}
#ecdb-fetching-message .ui.loader {
    margin-right: 10px !important;
}
.search-page-section {
    margin-bottom: 15px;
}

@media only screen and (max-width: 730px) {
    .search-container {
        gap: 25px;
        column-gap: 0;
    }
    .container-header {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
    .container-header > * {
        width: 100%;
    }
    .search-container .segment .section-row {
        flex-direction: column;
        gap: 5px;
    }
    .search-container .segment .section-row > * {
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .date-select .selection.date-short {
        min-width: 75px !important;
        width: 75px !important;
    }
    .date-select .selection.date-long {
        min-width: 110px !important;
        width: 110px !important;
    }
}
