@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto.ttf");
    font-weight: normal;
    font-style: normal;
}
html,
body {
    min-height: 100%;
    overflow: auto;
}
body {
    margin: 0 !important;
}
body > #root > #layout,
body > #root > #layout div,
body > #root .input input,
body > #root .button,
body > #root > #layout a,
body > #root label,
body > #root .divider,
body > #root .selection .item,
body > .ui.modals .header,
body > .ui.modals label,
body > .ui.modals input,
body > .ui.modals .button,
body > .ui.modals .divider,
body > .ui.modals .selection .item,
.ui.popup {
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
#layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
#layout header,
#layout footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
}
#layout main,
#layout footer > div {
    width: 90%;
}
#layout header .menu {
    width: 100%;
}
#layout header .menu .item,
#layout header .menu .right.item,
#layout footer div {
    padding: auto 15px !important;
    max-height: 20px;
}
#layout main {
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
#layout footer {
    margin-top: auto;
}
.ui.inverted.dimmer {
    width: 100vw !important;
    height: 100vh !important;
    padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2f4054;
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
#root p,
#root div,
#root label,
#root input,
#root .ui.table td,
#root .ui.selection,
#root .ui.selection .visible.menu,
.ui.dimmer > .ui.modal > .content input,
.ui.dimmer > .ui.modal > .content label,
.ui.dimmer > .ui.modal > .content .ui.selection,
.ui.dimmer > .ui.modal > .content .ui.selection .visible.menu .item,
.ui.dimmer > .ui.modal > .content h4,
body > .ui.modals * {
    color: #5f6774;
}
#root .ui.table th {
    color: #417ab3;
}
.ui.radio.checkbox label::before {
    border-radius: 5px !important;
    border: none !important;
}
#root input,
#root .ui.selection,
#root .ui.selection .visible.menu,
.ui.dimmer > .ui.modal > .content input,
.ui.dimmer > .ui.modal > .content .ui.selection,
.ui.dimmer > .ui.modal > .content .ui.selection .visible.menu {
    background-color: #fff !important;
    border: none !important;
}
#root div.ui.selection > div.visible.menu.transition,
.ui.dimmer > .ui.modal > .content div.ui.selection > div.visible.menu.transition {
    margin: 0;
    min-width: 100% !important;
    max-width: 100% !important;
}
#root div.ui.selection > input,
.ui.dimmer > .ui.modal > .content div.ui.selection > input {
    left: 0 !important;
    right: 0 !important;
}
#root input::placeholder,
.ui.dimmer > .ui.modal > .content input::placeholder {
    color: #5f6774 !important;
    font-weight: bold;
}
#root .ui.segment {
    border-radius: 15px;
    margin: 0;
    padding: 25px !important;
}
#root .ui.segment.grey-segment,
#root .ui.message {
    border: none;
    box-shadow: none;
    background: #f0f1f2;
}
.ui.dimmer > .ui.modal > .content .ui.message {
    border: none;
    box-shadow: none;
    background-color: #fff;
    border-radius: 8px;
}
.ui.popup {
    border: none !important;
    border-radius: 8px;
    box-shadow: none !important;
    background: #2f4054 !important;
    color: #fff !important;
    font-weight: bold !important;
}
.ui.popup::before {
    background: #2f4054 !important;
    border: none !important;
    box-shadow: none !important;
}
#root .ui.button,
.ui.dimmer > .ui.modal > .actions button {
    margin: 0;
}
#root .ui.button:hover,
.ui.dimmer > .ui.modal > .actions > .ui.button:hover {
    transition: all 0.1s ease-in-out;
    opacity: 0.8;
}
#root .ui.primary.button,
#root .ui.toggle.checkbox > input:checked ~ label:before,
.ui.dimmer > .ui.modal > .actions > .ui.primary.button,
.ui.dimmer > .ui.modal .ui.toggle.checkbox > input:checked ~ label:before {
    background-color: #417ab3 !important;
}
#root .ui.negative.button,
.ui.dimmer > .ui.modal > .actions > .ui.negative.button {
    background-color: #c0392b;
}
#root .ui.basic.primary.button {
    background-color: transparent !important;
    border-color: #417ab3 !important;
}
#root .ui.basic.negative.button {
    color: #c0392b !important;
    border-color: #c0392b !important;
}
#root .ui.small.basic.button {
    padding: 10px 15px !important;
}
.ui.dimmer > .ui.modal > .actions button:not(:first-of-type) {
    margin-left: 15px;
}
#root .ui.message,
#root .ui.button,
#root input,
#root .ui.selection,
.ui.dimmer > .ui.modal > .content .ui.button,
.ui.dimmer > .ui.modal > .actions .ui.button,
.ui.dimmer > .ui.modal > .content .ui.selection,
.ui.dimmer > .ui.modal > .content input,
.ui.popup {
    border-radius: 8px !important;
}
.section-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.section-row label,
.input-container label {
    font-weight: bold;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.input-container .checkbox {
    padding-top: 7px;
}
#root h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}
.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.table-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
    gap: 20px;
    margin-left: auto;
}
#root i.disabled {
    pointer-events: none;
}
body > div.ui.basic.segment.toast-container.visible.transition {
    width: 25%;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 5000;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 40px;
    margin: 0 !important;
    padding: 0 !important;
}
.toast-message {
    border-color: transparent !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    font-weight: bold;
    font-size: 14px !important;
    color: #fff !important;
    padding: 10px 14px !important;
    text-shadow: 1px 1px 2px #414042;
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
.positive.toast-message {
    background-color: #43a047 !important;
}
#root i.green {
    color: #43a047 !important;
}
.negative.toast-message {
    background-color: #c0392b !important;
}
#root .cell-bolded {
    font-weight: bold;
}
.container-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.container-header > * {
    margin-bottom: 0 !important;
}
#root .ui.selection .menu.transition,
#root .ui.selection.active.upward,
.ui.dimmer > .ui.modal > .content .ui.selection .menu.transition,
.ui.dimmer > .ui.modal > .content .ui.selection.active.upward {
    border-radius: 0 0 8px 8px !important;
}
#root .ui.selection.upward .menu.transition,
#root .ui.selection.active,
.ui.dimmer > .ui.modal > .content .ui.selection.upward .menu.transition,
.ui.dimmer > .ui.modal > .content .ui.selection.active {
    border-radius: 8px 8px 0 0 !important;
}
.ui.dimmer > .ui.modal {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.4) 0px 0px 25px 5px;
    background: #f0f1f2;
    border-radius: 15px;
    margin: 0;
}
.ui.dimmer > .ui.modal > div {
    background: #f0f1f2;
    color: #5f6774;
    padding-left: 25px;
    padding-right: 25px;
}
.ui.dimmer > .ui.modal > div:first-of-type {
    border-radius: 15px 15px 0 0;
}
.ui.dimmer > .ui.modal > div:last-of-type {
    border-radius: 0 0 15px 15px;
}
#root textarea,
.ui.dimmer > .ui.modal > .content textarea {
    border-style: none;
    border-radius: 10px;
    padding: 9.5px 14px;
    resize: none;
    outline: none;
}
#topAddCertificate {
    margin-bottom: 10px;
}
#bottomAddCertificate {
    margin-top: 10px;
}
#root button i,
.ui.dimmer > .ui.modal button i {
    font-size: 12px;
    color: #fff !important;
}
.input-required {
    margin-left: 2px;
    color: #c0392b !important;
}
.ui.dimmer > .qr-modal > .content {
    border-radius: 15px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
#login-error-message {
    text-align: center;
    font-weight: bold;
    height: 20px;
    margin: 10px 0 !important;
    color: #c0392b !important;
    min-height: 19px;
}
#root .error-message {
    padding: 0px;
}
#root .error-message p,
.ui.dimmer > .ui.modal > .content .error-message p,
#root .error-message i,
.ui.dimmer > .ui.modal > .content .error-message i {
    font-weight: bold;
    color: #c0392b !important;
}
#root .error-message p {
    display: flex;
    flex-direction: column;
}
.ui.dimmer > .ui.modal > .content .error-message {
    background-color: #c0392b33 !important;
}
.ui.inverted.dimmer {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.password-reset-errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-form-container .password-reset-errors {
    min-height: 100px;
}
.ui.dimmer > .ui.modal > .content .password-reset-errors .content p {
    display: flex;
    flex-direction: column;
}
#disabled-button,
#disabled-button:hover {
    opacity: 0.45 !important;
    cursor: initial !important;
}
.request-indicator {
    display: inline-block;
}
.request-indicator .check,
td.collapsing .check {
    color: #43a047;
}
.request-indicator .times {
    color: #c0392b;
}
.request-indicator .clock {
    color: #eed202;
}
.request-indicator .icon {
    margin: 0;
}
#root td.collapsing .ui.dropdown {
    border: 1px solid #417ab3;
    border-radius: 8px;
    padding: 6px 12px;
    min-width: 100px !important;
    margin-right: 5px;
}
#root td.collapsing .ui.dropdown,
#root td.collapsing .ui.dropdown * {
    color: #417ab3 !important;
}
#root td.collapsing .ui.dropdown .menu.transition.visible {
    box-shadow: none !important;
    border: 1px solid #417ab3;
    border-radius: 8px;
    width: 100%;
    min-width: auto;
}
#root td.collapsing #certificates-options .divider.text,
#root td.collapsing #srbs-options .divider.text {
    width: 85%;
}
#certificates-options {
    width: 195px;
}
#certificates-options .menu {
    z-index: 1;
}

#srbs-options {
    width: 270px;
}
@media only screen and (max-width: 730px) {
    #root .ui.segment {
        max-width: 100% !important;
        min-width: 100% !important;
        padding: 25px !important;
    }
    .ui.dimmer > .ui.modal > .content form,
    .add-certificate-form,
    .row-container {
        display: flex !important;
        flex-direction: column !important;
    }
    .row-container {
        gap: 10px;
    }
    .ui.dimmer > .ui.modal > .actions {
        padding-bottom: 14px !important;
    }
}
