.date-input {
    position: relative;
    width: 300px !important;
    transition: all 0.2s ease-in-out !important;
}
.calendar-input > .date-input.calendar-open,
.input-container > .calendar-input > .date-input.calendar-open {
    border-radius: 8px 8px 0 0 !important;
}
.react-calendar {
    background-color: #fff !important;
    border-color: #f0f1f2 !important;
    border-top-color: transparent !important;
}
.portal-calendar {
    position: absolute;
    top: 36px;
    z-index: 1000;
    border-radius: 0 0 8px 8px !important;
}
.show-calendar-enter {
    opacity: 0;
    transform: scale(0.9);
}
.show-calendar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.show-calendar-exit {
    opacity: 1;
}
.show-calendar-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.react-calendar button,
.react-calendar span,
.react-calendar abbr {
    color: #5f6774 !important;
    text-decoration: none !important;
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    font-size: 12px !important;
    background-color: transparent !important;
}
.react-calendar button {
    padding: 7px !important;
    border-radius: 8px !important;
    transition: all 0.1s ease-in-out !important;
}
.react-calendar .react-calendar__tile--active {
    background-color: #2c658526 !important;
}
.react-calendar__navigation button {
    padding: 2px !important;
}
#root .react-calendar__navigation button i {
    color: #2f4054 !important;
}
main .react-calendar .react-calendar__month-view__days__day--neighboringMonth abbr {
    color: #41404280 !important;
}
.react-calendar button:hover {
    background-color: #2c658526 !important;
}
